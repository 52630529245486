import Decimal from 'decimal.js'
import { deepCopy } from '@/utils/common'
import { el } from 'date-fns/locale'

export const getSortedOrdersByRate = orders => orders.sort((a, b) => new Decimal(a.exchange_rate).minus(b.exchange_rate).toNumber())

export const getGlassAverageByAmount = (amount, sortedOrders, orderAmountProp, rateDecimalPlaces) => {
  let _amount = new Decimal(amount)
  let total = new Decimal(0)
  for (let order of sortedOrders) {
    if (_amount.lte(order[orderAmountProp])) {
      total = total.plus(_amount.mul(order.exchangeRate))
      _amount = new Decimal(0)
      break
    }
    _amount = _amount.minus(order[orderAmountProp])
    total = total.plus(new Decimal(order[orderAmountProp]).mul(order.exchangeRate))
  }
  if (_amount.toNumber() !== 0) {
    const lastOrderRate = sortedOrders[sortedOrders.length - 1].exchangeRate
    total = total.plus(_amount.mul(lastOrderRate))
  }
  return total.div(amount).toDecimalPlaces(rateDecimalPlaces).toString()
}

// export const matchOrders = (glassObj) => {
//   const sellRates = Object.keys(glassObj?.sell || {}).sort((a, b) => a - b)
//   const buyOrders = Object.keys(glassObj?.buy || {}).sort((a, b) => b - a)
//   console.log({ sellRates, buyOrders })
//   const firstBuyRate = buyOrders[0]
//   if (!firstBuyRate) return glassObj
//   const buyOrder = glassObj.buy[firstBuyRate][0]
//   if (!buyOrder) return glassObj
//   for (let sellRate of sellRates) {
//     if (new Decimal(firstBuyRate).gt(sellRate)) break
//     const rateSellOrders = glassObj.sell[sellRate]
//     rateSellOrders.forEach(sellOrder => {
//       const buyOrderTimestamp = buyOrder.timestamp
//       const sellOrderTimestamp = sellOrder.timestamp
//     })
//   }
//   return glassObj
// }

export function makeDeals(buyOrders, sellOrders) {
  const _buyOrders = deepCopy(buyOrders)
  const _sellOrders = deepCopy(sellOrders)
  // Сортировка массивов по времени создания заявок
  _buyOrders.sort((a, b) => a.timestamp - b.timestamp);
  _sellOrders.sort((a, b) => a.timestamp - b.timestamp);
  console.log({
    _buyOrders: _buyOrders.map(({ exchange_rate, timestamp, first_amount, is_market_order = false }) => ({ exchange_rate, timestamp, first_amount, is_market_order })),
    _sellOrders: _sellOrders.map(({ exchange_rate, timestamp, first_amount, is_market_order = false }) => ({ exchange_rate, timestamp, first_amount, is_market_order })) })

  let i = 0; // Индекс текущей заявки на покупку
  let j = 0; // Индекс текущей заявки на продажу

  const dealAction = (buyOrder, sellOrder) => {
    const volume = Decimal.min(buyOrder.first_amount, sellOrder.first_amount);
    buyOrder.first_amount = new Decimal(buyOrder.first_amount).minus(volume).toFixed()
    sellOrder.first_amount = new Decimal(sellOrder.first_amount).minus(volume).toFixed()

    if (new Decimal(sellOrder.first_amount).eq(0)) {
      _sellOrders.splice(j, 1);
    }

    if (new Decimal(buyOrder.first_amount).eq(0)) {
      _buyOrders.splice(i, 1);
      j = 0
    }
  }

  // while (i < _buyOrders.length && j < _sellOrders.length) {
  while (i < _buyOrders.length) {
    const buyOrder = _buyOrders[i];
    const sellOrder = _sellOrders[j];
    // если уже прошли все заявки на продажу,
    // то переходим к следующей заявке на покупку
    if (!sellOrder) {
      j = 0
      i++;
      continue
    }
    console.log('ONO', { buyOrder: [buyOrder.timestamp, buyOrder.exchange_rate, buyOrder.first_amount, buyOrder.is_market_order], sellOrder: [sellOrder?.timestamp, sellOrder?.exchange_rate, sellOrder?.first_amount, sellOrder?.is_market_order] })
    const isExclude = new Decimal(buyOrder.exchange_rate || 0).gt(sellOrder.exchange_rate || 0)
    if (sellOrder.is_market_order && buyOrder.is_market_order) {
      console.log('TUTUTUTUTUUTUT')
      j++
      continue
    }
    if (sellOrder.is_market_order) {
      if (sellOrder.timestamp >= buyOrder.timestamp) dealAction(buyOrder, sellOrder)
      else j++
    // правило для маркет заявки и для исключения
    } else if (buyOrder.is_market_order || isExclude) {
      if (sellOrder.timestamp <= buyOrder.timestamp) {
        dealAction(buyOrder, sellOrder)
      } else {
        _buyOrders.splice(i, 1);
        j = 0
      }
    } else if (sellOrder.is_market_order) {
      if (sellOrder.timestamp >= buyOrder.timestamp) dealAction(buyOrder, sellOrder)
      else j++
    // обычная заявка
    } else if (new Decimal(buyOrder.exchange_rate || 0).eq(sellOrder.exchange_rate || 0)) {
      dealAction(buyOrder, sellOrder)
    } else if (j < _sellOrders.length) {
      j++
    }
  }
  return { buy: _buyOrders, sell: _sellOrders };
}


export const makeDealsV2 = (orders, minFirstAmount) => {
  const _orders = deepCopy(orders)
  let currentIdx = 0
  let orderForDealIdx = 1

  const dealAction = (currentOrder, orderForDeal) => {
    const volume = Decimal.min(currentOrder.first_amount, orderForDeal.first_amount);
    const minSecondAmount = Decimal.min(currentOrder.second_amount, orderForDeal.second_amount)
    currentOrder.first_amount = new Decimal(currentOrder.first_amount).minus(volume).toFixed()
    orderForDeal.first_amount = new Decimal(orderForDeal.first_amount).minus(volume).toFixed()
    if (orderForDeal.is_market_order && orderForDeal.type === 'buy') {
      orderForDeal.second_amount = new Decimal(orderForDeal.second_amount).minus(minSecondAmount).toFixed()
    }

    // if (new Decimal(orderForDeal.first_amount).eq(0)) {
    if (new Decimal(orderForDeal.first_amount).lt(minFirstAmount)) {
      _orders.splice(orderForDealIdx, 1)
    }

    // if (new Decimal(currentOrder.first_amount).eq(0)) {
    if (new Decimal(currentOrder.first_amount).lt(minFirstAmount)) {
      _orders.splice(currentIdx, 1)
      orderForDealIdx = currentIdx + 1
    }
  }

  while (currentIdx < _orders.length) {
    const currentOrder = _orders[currentIdx]
    const orderForDeal = _orders[orderForDealIdx]
    if (currentOrder.is_market_order) {
      _orders.splice(currentIdx, 1)
      continue
    }
    if (orderForDealIdx === _orders.length) {
      currentIdx++
      orderForDealIdx = currentIdx + 1
      continue
    }
    if (currentOrder.type === orderForDeal.type) {
      orderForDealIdx++
      continue
    }
    const operator = currentOrder.type === 'sell' ? 'lt' : 'gt'
    if (orderForDeal.is_market_order) {
      if (orderForDeal.type === 'buy') orderForDeal.first_amount = new Decimal(orderForDeal.second_amount).div(currentOrder.exchange_rate).toFixed()
      dealAction(currentOrder, orderForDeal)
    } else if (
      new Decimal(currentOrder.exchange_rate)[operator](orderForDeal.exchange_rate) ||
      new Decimal(currentOrder.exchange_rate).eq(orderForDeal.exchange_rate)
    ) {
      dealAction(currentOrder, orderForDeal)
    } else orderForDealIdx++
  }
  return _orders
}

export const makeDealsV3 = (orders, decimalPlacesFa, decimalPlacesSa) => {
  const _orders = deepCopy(orders)
  let iterCount = 0
  const dealAction = (marketMaker, marketTaker) => {
    const rateForDeal = marketMaker.exchange_rate
    const makerIsSell = marketMaker.type === 'sell'
    const takerIsSell = marketTaker.type === 'sell'

    if (makerIsSell) marketMaker.second_amount = new Decimal(rateForDeal).mul(marketMaker.first_amount)
    if (takerIsSell) marketTaker.second_amount = new Decimal(rateForDeal).mul(marketTaker.first_amount)

    const saVolume = Decimal.min(marketMaker.second_amount, marketTaker.second_amount)
    const faVolume = new Decimal(saVolume).div(rateForDeal)

    if (makerIsSell) {
      marketMaker.first_amount = new Decimal(marketMaker.first_amount).minus(faVolume).toFixed(decimalPlacesFa, Decimal.ROUND_DOWN)
      marketMaker.second_amount = new Decimal(marketMaker.exchange_rate).mul(marketMaker.first_amount).toFixed(decimalPlacesSa, Decimal.ROUND_DOWN)

      marketTaker.second_amount = new Decimal(marketTaker.second_amount).minus(saVolume).toFixed(decimalPlacesSa, Decimal.ROUND_DOWN)
      marketTaker.first_amount = new Decimal(marketTaker.second_amount).div(marketTaker.exchange_rate).toFixed(decimalPlacesFa, Decimal.ROUND_DOWN)
    } else {
      marketTaker.first_amount = new Decimal(marketTaker.first_amount).minus(faVolume).toFixed(decimalPlacesFa, Decimal.ROUND_DOWN)
      marketTaker.second_amount = new Decimal(marketTaker.exchange_rate).mul(marketTaker.first_amount).toFixed(decimalPlacesSa, Decimal.ROUND_DOWN)

      marketMaker.second_amount = new Decimal(marketMaker.second_amount).minus(saVolume).toFixed(decimalPlacesSa, Decimal.ROUND_DOWN)
      marketMaker.first_amount = new Decimal(marketMaker.second_amount).div(marketMaker.exchange_rate).toFixed(decimalPlacesFa, Decimal.ROUND_DOWN)
    }
  }
  _orders.forEach((currentOrder, orderIdx) => {
    iterCount++
    const isSell = currentOrder.type === 'sell'
    if (!isSell) {
      currentOrder.first_amount = new Decimal(currentOrder.second_amount).div(currentOrder.exchange_rate).toFixed(decimalPlacesFa, Decimal.ROUND_DOWN)
    }
    const typeForSearch = isSell ? 'buy' : 'sell'
    const amountPropForSearch  = typeForSearch === 'sell' ? 'first_amount' : 'second_amount'
    const tempOrders = _orders.slice(0, orderIdx).filter(i => new Decimal(i[amountPropForSearch]).gt(0))
    const ordersForMatch = tempOrders.filter(i => {
      iterCount++
      return i.type === typeForSearch
    })
    ordersForMatch.sort((a, b) => {
      iterCount++
      const minuend = isSell ? b : a
      const subtrahend = isSell ? a : b
      return new Decimal(minuend.exchange_rate || 0).minus(subtrahend.exchange_rate || 0).toNumber()
    })
    for (let orderForDeal of ordersForMatch) {
      iterCount++
      if (
        (currentOrder.type === 'sell' && new Decimal(currentOrder.first_amount).eq(0)) ||
        (currentOrder.type === 'buy' && new Decimal(currentOrder.second_amount).eq(0))
      ) break
      const operator = currentOrder.type === 'sell' ? 'lt' : 'gt'
      if (
        currentOrder.is_market_order ||
        new Decimal(currentOrder.exchange_rate)[operator](orderForDeal.exchange_rate) ||
        new Decimal(currentOrder.exchange_rate).eq(orderForDeal.exchange_rate)
      ) {
        dealAction(orderForDeal, currentOrder)
      }
    }
    if (currentOrder.is_market_order) {
      currentOrder.first_amount = 0
      currentOrder.second_amount = 0
    }
  })
  return _orders.filter(i => !new Decimal(i.first_amount).eq(0))
}

export const makeDealsV4 = (orders, minFirstAmount) => {
  let iterCount = 0
  const decimalPlaces = minFirstAmount.split('.')[1]?.length || 2
  const matches = []

  const doOrdersByType = (__orders) => {
    const result = { sell: [], buy: [] }
    __orders.forEach(i => {
      iterCount++
      result[i.type].push(i)
    })
    return result
  }
  const doRateMap = (_ordersByType) => {
    const result = { sell: {}, buy: {} }
    _ordersByType.sell.forEach((i, idx) => {
      i.idxInTypeArr = idx
      iterCount++
      if (!result[i.type][i.exchange_rate]) result[i.type][i.exchange_rate] = []
      result[i.type][i.exchange_rate].push(i)
    })
    _ordersByType.buy.forEach((i, idx) => {
      i.idxInTypeArr = idx
      iterCount++
      if (!result[i.type][i.exchange_rate]) result[i.type][i.exchange_rate] = []
      result[i.type][i.exchange_rate].push(i)
    })
    return result
  }

  const getMarketIntersections = (_ordersMap) => {
    const sellOrders = _ordersMap.sell
    const buyOrders = _ordersMap.buy
    const marketOrders = []
    const result = []
    if (sellOrders['']?.length) marketOrders.push(...sellOrders[''])
    if (buyOrders['']?.length) marketOrders.push(...buyOrders[''])
    marketOrders.sort((a, b) => a.timestamp - b.timestamp)
    for (let marketOrder of marketOrders) {
      const isSell = marketOrder.type === 'sell'
      const marketMakersType = isSell ? 'buy' : 'sell'
      const condition = (a, b) => {
        const minuend = isSell ? b : a
        const subtrahend = isSell ? a : b
        return new Decimal(minuend.exchange_rate || 0).minus(subtrahend.exchange_rate || 0).toNumber()
      }
      const marketMakers = ordersByType[marketMakersType].filter(i => i.exchange_rate && i.timestamp < marketOrder.timestamp)
      marketMakers.sort(condition)
      if (marketMakers[0]) {
        result.push(marketMakers[0], marketOrder)
        break
      }
    }
    return result
  }

  const getAbnormalIntersections = (_ordersMap) => {
    const result = []
    const buyIntersections = new Set()
    const sellIntersections = new Set()
    Object.keys(_ordersMap.buy).forEach(buyRate => {
      if (!buyRate) return
      const rateIntersections = Object.keys(_ordersMap.sell).filter(sellRate => {
        return sellRate && new Decimal(buyRate).gte(sellRate)
      })
      if (rateIntersections) {
        buyIntersections.add(buyRate)
        rateIntersections.forEach(i => sellIntersections.add(i))
      }
    })
    const allIntersections = []
    buyIntersections.forEach(i => {
      allIntersections.push(..._ordersMap.buy[i])
    })
    sellIntersections.forEach(i => {
      allIntersections.push(..._ordersMap.sell[i])
    })
    allIntersections.sort((a, b) => {
      return a.timestamp - b.timestamp
    })
    let orderIdx = 1
    while (orderIdx < allIntersections.length) {
      const currentOrder = allIntersections[orderIdx]
      const currentIsSell = currentOrder.type === 'sell'
      const orderDealType = currentIsSell ? 'buy' : 'sell'
      const operator = currentIsSell ? 'lte' : 'gte'
      const condition = (a, b) => {
        const minuend = currentIsSell ? b : a
        const subtrahend = currentIsSell ? a : b
        return new Decimal(minuend.exchange_rate || 0).minus(subtrahend.exchange_rate || 0).toNumber()
      }
      const previousOrders = allIntersections.slice(0, orderIdx)
      previousOrders.sort(condition)
      const ordersForDeal = previousOrders.filter(i => {
        return i.type === orderDealType && new Decimal(currentOrder.exchange_rate)[operator](i.exchange_rate)
      })
      if (ordersForDeal.length) {
        result.push(ordersForDeal[0], currentOrder)
        break
      }
      orderIdx++
    }
    return result
  }

  const getIntersections = (_ordersMap) => {
    const resultMap = {}
    const buyRates = Object.keys(_ordersMap.buy).sort((a, b) => {
      iterCount++
      if (!a || !b) return 1
      return new Decimal(a).minus(b).toNumber()
    })
    const sellRates = Object.keys(_ordersMap.sell).sort((a, b) => {
      iterCount++
      if (!a || !b) return 1
      return new Decimal(b).minus(a).toNumber()
    })
    buyRates.filter(Boolean).forEach(bRate => {
      iterCount++
      if (!resultMap[bRate]) resultMap[bRate] = []
      resultMap[bRate].push(bRate)
    })
    sellRates.filter(Boolean).forEach(sRate => {
      iterCount++
      if (!resultMap[sRate]) resultMap[sRate] = []
      resultMap[sRate].push(sRate)
    })
    return Object.keys(resultMap).filter(rate => {
      iterCount++
      return resultMap[rate].length > 1
    })
  }

  const getMinTimestampObj = (arr) => {
    if (!arr.length) return
    arr.sort((a, b) => {
      iterCount++
      return a.timestamp - b.timestamp
    })
    return arr[0]
  }

  const doFindFirstMatch = (_intersections, _ordersMap) => {
    const marketMakers = []
    const marketTakers = []
    _intersections.forEach(i => {
      iterCount++
      if (_ordersMap.sell[i][0].timestamp > _ordersMap.buy[i][0].timestamp) {
        marketMakers.push(_ordersMap.buy[i][0])
        marketTakers.push(_ordersMap.sell[i][0])
      } else {
        marketMakers.push(_ordersMap.sell[i][0])
        marketTakers.push(_ordersMap.buy[i][0])
      }
    })
    // const marketTakers = _intersections.map(i => {
    //   const maxTimestampInPair = Decimal.max(_ordersMap.sell[i][0].timestamp, _ordersMap.buy[i][0].timestamp)
    //   return [_ordersMap.sell[i][0], _ordersMap.buy[i][0]].find(offer => maxTimestampInPair.eq(offer.timestamp))
    // })
    // const minMarketTakerTimestamp = Decimal.min(...marketTakers.map(i => i.timestamp))
    // return marketTakers.find(i => minMarketTakerTimestamp.eq(i.timestamp))
    const firstMarketTaker = getMinTimestampObj(marketTakers)
    return [marketMakers.find(i => {
      iterCount++
      return i.exchange_rate === firstMarketTaker.exchange_rate
    }), firstMarketTaker].filter(Boolean)
  }

  //нужно найти сначала заявки по полному пересечению, затем найти самое первое пересечение маркет заявки
  // с чем то раньше по самому выгодному курсу, и найти первое аномальное пересечение, далее нужно из этих трех пар найти самое первое

  // ищем аномалии по возможным пересечениям курсов, после этого раскладываем в плоский список по времени
  // и берем каждую заявку и ищем ей пару назад от текущей по выгодности курса

  // далее из этих трех пар берем самый первый маркет тейкер
  function doFindFirstTakerForMatch () {
    if (!minMarketTaker) {
      return
    }
    const takerIsSell = minMarketTaker.type === 'sell'
    const typeForSearch = takerIsSell ? 'sell' : 'buy'
    const operator = takerIsSell ? 'lt' : 'gt'
    const earliestOrders = ordersByType[typeForSearch].filter(order => {
      iterCount++
      // убрать проверку на exchange rate и нужно сравнивать timestamp с minMarketTaker
      // и курс у order должен быть больше чем у мейкера или отсутствовать
      if (!minMarketTaker.exchange_rate) {
        console.log('TUT')
      }
      return order.timestamp < minMarketTaker.timestamp && (!order.exchange_rate || new Decimal(order.exchange_rate)[operator](minMarketTaker.exchange_rate))
    })
    if (earliestOrders.length) {
      minMarketTaker = minMarketMaker
      minMarketMaker = earliestOrders.at(-1)
      doFindFirstTakerForMatch()
    }
    // console.log({ earliestOrders })
  }
  const _orders = deepCopy(orders)

  let ordersByType = doOrdersByType(_orders)
  let ordersMap = doRateMap(ordersByType)
  console.log(deepCopy(ordersMap))

  let intersections = getIntersections(ordersMap)

  let marketIntersections = getMarketIntersections(ordersMap)

  let abnormalIntersections = getAbnormalIntersections(ordersMap)

  // let completeIntersections = doFindFirstMatch(intersections, ordersMap)
  let minMarketMaker = abnormalIntersections?.[0]
  let minMarketTaker = abnormalIntersections?.[1]
  // console.log({ minMarketMaker, minMarketTaker })
  let pairs = []
  if (marketIntersections.length) pairs.push(marketIntersections)
  if (abnormalIntersections.length) pairs.push(abnormalIntersections)
  // if (completeIntersections.length) pairs.push(completeIntersections)
  if (!pairs.length) return
  pairs.forEach(pair => {
    if (!minMarketTaker || pair[1].timestamp < minMarketTaker.timestamp) {
      minMarketMaker = pair[0]
      minMarketTaker = pair[1]
    }
  })
  // doFindFirstTakerForMatch()

  // let marketTakerIdx = ordersByType[minMarketTaker.type].findIndex(i => i.timestamp === minMarketTaker.timestamp)
  let marketTakerIdx = minMarketTaker.idxInTypeArr

  let startMarketMaker = null

  const fromTakerToMakers = () => {
    const marketTaker = ordersByType[minMarketTaker.type][marketTakerIdx]
    const marketMakersForDial = ordersByType[minMarketMaker.type].filter(i => {
      iterCount++
      return i.timestamp < marketTaker.timestamp
    })
    marketMakersForDial.sort((a, b) => {
      iterCount++
      const isSell = marketTaker.type === 'sell'
      const minuend = isSell ? b : a
      const subtrahend = isSell ? a : b
      return new Decimal(minuend.exchange_rate || 0).minus(subtrahend.exchange_rate || 0).toNumber()
    })
    // console.log({ marketMakersForDial })
    startMarketMaker = marketTaker
    for (let orderForDeal of marketMakersForDial) {
      iterCount++
      const volume = Decimal.min(marketTaker.first_amount, orderForDeal.first_amount);
      const minSecondAmount = Decimal.min(marketTaker.second_amount, orderForDeal.second_amount)
      marketTaker.first_amount = new Decimal(marketTaker.first_amount).minus(volume).toFixed()
      orderForDeal.first_amount = new Decimal(orderForDeal.first_amount).minus(volume).toFixed()
      if (new Decimal(marketTaker.first_amount).eq(0)) {
        if (new Decimal(orderForDeal.first_amount).eq(0)) {
          retryStartActions()
          break
        }
        startMarketMaker = orderForDeal
      }
    }
  }

  const dealAction = (marketMaker, marketTaker) => {
    if (new Decimal(marketTaker.first_amount || 0).eq(0) && new Decimal(marketTaker.second_amount || 0).eq(0)) return
    matches.push(deepCopy(marketMaker), deepCopy(marketTaker))
    const rateForDeal = marketMaker.exchange_rate
    // if (!marketTaker.exchange_rate) {
    //   if (marketTaker.type === 'buy') marketTaker.first_amount = new Decimal(marketTaker.second_amount).div(rateForDeal).toFixed()
    //   else marketTaker.second_amount = new Decimal(marketTaker.first_amount).mul(rateForDeal).toFixed()
    // } else {
    //   if (marketMaker.type === 'buy') {
    //     marketMaker.first_amount = new Decimal(marketMaker.second_amount).div(rateForDeal).toFixed()
    //   } else {
    //     marketMaker.second_amount = new Decimal(marketMaker.first_amount).mul(rateForDeal).toFixed()
    //   }
    //   if (marketTaker.type === 'buy') {
    //     marketTaker.first_amount = new Decimal(marketTaker.second_amount).div(rateForDeal).toFixed()
    //   } else {
    //     marketTaker.second_amount = new Decimal(marketTaker.first_amount).mul(rateForDeal).toFixed()
    //   }
    // }
    if (marketMaker.type === 'buy') {
      marketMaker.first_amount = new Decimal(marketMaker.second_amount).div(rateForDeal).toFixed()
    } else {
      marketMaker.second_amount = new Decimal(marketMaker.first_amount).mul(rateForDeal).toFixed()
    }
    if (marketTaker.type === 'buy') {
      marketTaker.first_amount = new Decimal(marketTaker.second_amount).div(rateForDeal).toFixed()
    } else {
      marketTaker.second_amount = new Decimal(marketTaker.first_amount).mul(rateForDeal).toFixed()
    }
    const volume = Decimal.min(marketMaker.first_amount, marketTaker.first_amount)
    const volumeSecondAmount = new Decimal(rateForDeal).mul(volume)
    marketMaker.first_amount = new Decimal(marketMaker.first_amount).minus(volume).toFixed(decimalPlaces, Decimal.ROUND_DOWN)
    marketTaker.first_amount = new Decimal(marketTaker.first_amount).minus(volume).toFixed(decimalPlaces, Decimal.ROUND_DOWN)

    marketMaker.second_amount = new Decimal(marketMaker.second_amount).minus(volumeSecondAmount).toFixed(decimalPlaces, Decimal.ROUND_DOWN)
    marketTaker.second_amount = new Decimal(marketTaker.second_amount).minus(volumeSecondAmount).toFixed(decimalPlaces, Decimal.ROUND_DOWN)
    // marketMaker.second_amount = new Decimal(rateForDeal).mul(marketMaker.first_amount).toFixed()
    // marketTaker.second_amount = new Decimal(rateForDeal).mul(marketTaker.first_amount).toFixed()
  }
  // fromTakerToMakers()

  function retryStartActions () {
    const filteredOrders = _orders.filter(i => {
      if (i.type === 'buy') return !new Decimal(i.second_amount).eq(0)
      return !new Decimal(i.first_amount).eq(0)
    })
    ordersByType = doOrdersByType(filteredOrders)
    ordersMap = doRateMap(ordersByType)

    intersections = getIntersections(ordersMap)

    marketIntersections = getMarketIntersections(ordersMap)

    abnormalIntersections = getAbnormalIntersections(ordersMap)

    // completeIntersections = doFindFirstMatch(intersections, ordersMap)
    minMarketMaker = abnormalIntersections?.[0]
    minMarketTaker = abnormalIntersections?.[1]
    // console.log({ minMarketMaker, minMarketTaker })
    pairs = []
    if (marketIntersections.length) pairs.push(marketIntersections)
    if (abnormalIntersections.length) pairs.push(abnormalIntersections)
    // if (completeIntersections.length) pairs.push(completeIntersections)
    pairs.forEach(pair => {
      if (!minMarketTaker || pair[1].timestamp < minMarketTaker.timestamp) {
        minMarketMaker = pair[0]
        minMarketTaker = pair[1]
      }
    })
    // doFindFirstTakerForMatch()

    // let marketTakerIdx = ordersByType[minMarketTaker.type].findIndex(i => i.timestamp === minMarketTaker.timestamp)
    if (!minMarketTaker) return
    marketTakerIdx = minMarketTaker.idxInTypeArr
    currentMakerType = minMarketMaker.type
    currentMarketMakerIdx = minMarketMaker.idxInTypeArr
    orderForDealIdx = 0
    // marketOrderByMakerType = ordersByType[currentMakerType].find(i => !i.exchange_rate && !new Decimal(i.first_amount).eq(0))
  }
  let currentMakerType = minMarketMaker.type
  let currentMarketMakerIdx = minMarketMaker.idxInTypeArr
  let orderForDealIdx = 0
  // let marketOrderByMakerType = null
  while (currentMarketMakerIdx < ordersByType[currentMakerType].length) {
    iterCount++
    if (!minMarketMaker || !minMarketTaker) break
    // сейчас попадает маркет заявка в мейкер, но такого быть не может, мы должны на предыдущей итерации от маркет заявку выкупить в ноль в обратную сторону от ее timestamp, если ничего не осталось сзади, а объем остался у маркета, то обнуляем его
    let currentMarketMaker = ordersByType[currentMakerType][currentMarketMakerIdx]
    // marketOrderByMakerType = ordersByType[currentMakerType].find(i => !i.exchange_rate && !new Decimal(i.first_amount).eq(0))
    if (new Decimal(currentMarketMaker.first_amount).eq(0)) {
      currentMarketMakerIdx++
      continue
    }
    const isSell = currentMakerType === 'sell'
    let typeForDeal = isSell ? 'buy' : 'sell'
    // const operator = isSell ? 'lte' : 'gte'
    // const earliestOrders = ordersByType[typeForDeal].filter(order => {
    //   iterCount++
    //   return ((!order.exchange_rate && !new Decimal(order.first_amount).eq(0)) ||
    //     (!new Decimal(order.first_amount).eq(0) &&
    //       (!currentMarketMaker.exchange_rate ||
    //       new Decimal(currentMarketMaker.exchange_rate)[operator](order.exchange_rate)))) &&
    //     order.timestamp < currentMarketMaker.timestamp
    // })
    if (!currentMarketMaker.exchange_rate) {
      currentMarketMaker.first_amount = 0
      currentMarketMakerIdx++
      continue
    }
    orderForDealIdx = minMarketTaker?.idxInTypeArr
    // orderForDealIdx = ordersByType[typeForDeal].findIndex(i => {
    //   iterCount++
    //   return !new Decimal(i.first_amount).eq(0) &&
    //     i.timestamp > currentMarketMaker.timestamp &&
    //     (!i.exchange_rate || new Decimal(currentMarketMaker.exchange_rate)[operator](i.exchange_rate)) &&
    //     (!marketOrderByMakerType || marketOrderByMakerType.timestamp > i.timestamp)
    // })
    if (!~orderForDealIdx) {
      currentMarketMakerIdx++
      continue
    }
    // while (currentMarketMaker.idxInTypeArr < ordersByType[currentMakerType].length) {
    const orderForDeal = ordersByType[typeForDeal][orderForDealIdx]

    dealAction(currentMarketMaker, orderForDeal)
    if (!orderForDeal.exchange_rate && !new Decimal(orderForDeal.first_amount).eq(0)) {
      const operator = getOperatorForCurr(orderForDeal.type)
      const earliestOrders = ordersByType[getInvertedType(orderForDeal.type)].filter(order => {
        iterCount++
        const isSellOrder = order.type === 'sell'
        const propForCompare = isSellOrder ? 'first_amount' : 'second_amount'
        // return ((!order.exchange_rate && !new Decimal(order.first_amount).eq(0)) ||
        //   (!new Decimal(order.first_amount).eq(0) &&
        //     (!orderForDeal.exchange_rate ||
        //     new Decimal(orderForDeal.exchange_rate)[operator](order.exchange_rate)))) &&
        //   order.timestamp < orderForDeal.timestamp
        return !new Decimal(order[propForCompare]).eq(0) && order.timestamp < orderForDeal.timestamp
      })
      if (earliestOrders?.length) {
        earliestOrders.sort(getCbForSortRates(orderForDeal.type))
        earliestOrders.forEach(order => {
          dealAction(order, orderForDeal)
        })
      }
      orderForDeal.first_amount = "0"
      orderForDeal.second_amount = "0"
    }
    retryStartActions()
    // if (earliestOrders.length) {
    //   minMarketTaker = minMarketMaker
    //   minMarketMaker = earliestOrders.at(-1)
    //   doFindFirstTakerForMatch()
    // }
    // if (!earliestOrders.length) {
    //   if (!currentMarketMaker.exchange_rate) {
    //     currentMarketMaker.first_amount = 0
    //     currentMarketMakerIdx++
    //     continue
    //   }
    //   orderForDealIdx = minMarketTaker?.idxInTypeArr
    //   // orderForDealIdx = ordersByType[typeForDeal].findIndex(i => {
    //   //   iterCount++
    //   //   return !new Decimal(i.first_amount).eq(0) &&
    //   //     i.timestamp > currentMarketMaker.timestamp &&
    //   //     (!i.exchange_rate || new Decimal(currentMarketMaker.exchange_rate)[operator](i.exchange_rate)) &&
    //   //     (!marketOrderByMakerType || marketOrderByMakerType.timestamp > i.timestamp)
    //   // })
    //   if (!~orderForDealIdx) {
    //     currentMarketMakerIdx++
    //     continue
    //   }
    //   // while (currentMarketMaker.idxInTypeArr < ordersByType[currentMakerType].length) {
    //   const orderForDeal = ordersByType[typeForDeal][orderForDealIdx]
    //   const volume = Decimal.min(currentMarketMaker.first_amount, orderForDeal.first_amount);
    //   const minSecondAmount = Decimal.min(currentMarketMaker.second_amount, orderForDeal.second_amount)
    //   currentMarketMaker.first_amount = new Decimal(currentMarketMaker.first_amount).minus(volume).toFixed()
    //   orderForDeal.first_amount = new Decimal(orderForDeal.first_amount).minus(volume).toFixed()
    //   // let orderForDealIsEmpty = false
    //   // if (new Decimal(orderForDeal.first_amount).eq(0)) {
    //   //   orderForDealIdx = ordersByType[typeForDeal].findIndex((i, idx) => {
    //   //     iterCount++
    //   //     return i.timestamp > currentMarketMaker.timestamp &&
    //   //       (!i.exchange_rate || new Decimal(currentMarketMaker.exchange_rate)[operator](i.exchange_rate)) &&
    //   //       idx > orderForDealIdx &&
    //   //       (!marketOrderByMakerType || marketOrderByMakerType.timestamp > i.timestamp)
    //   //   })
    //   //   orderForDealIsEmpty = true
    //   //   if (!~orderForDealIdx) {
    //   //     currentMarketMakerIdx++
    //   //     continue
    //   //   }
    //   //   // Запоминаем эту пару(когда тейкером была маркет заявка и остался мейкер), ищем первый матчинг(начинаем сначала)
    //   //   // и добавляем эту пару в pairs и дальше ищем там первый тейкер
    //   //   if (!orderForDeal.exchange_rate) {
    //   //     retryStartActions()
    //   //     continue
    //   //   }
    //   // }
    //   // if (new Decimal(currentMarketMaker.first_amount).eq(0)) {
    //   //   if (orderForDealIsEmpty) {
    //   //     retryStartActions()
    //   //     // currentMarketMakerIdx++
    //   //     continue
    //   //   }
    //   //   if (orderForDeal.exchange_rate) {
    //   //     currentMakerType = orderForDeal.type
    //   //     currentMarketMakerIdx = orderForDeal.idxInTypeArr
    //   //   } else {
    //   //     const dealIsSell = orderForDeal.type === 'sell'
    //   //     const invertedType = dealIsSell ? 'buy' : 'sell'
    //   //     const previousOrders = ordersByType[invertedType].filter(i => {
    //   //       return !new Decimal(i.first_amount).eq(0) && i.timestamp < orderForDeal.timestamp && i.exchange_rate
    //   //     })
    //   //     previousOrders.sort(getCbForSortRates(orderForDeal.type))
    //   //     for (let prevOrder of previousOrders) {
    //   //       const volume = Decimal.min(prevOrder.first_amount, orderForDeal.first_amount);
    //   //       const minSecondAmount = Decimal.min(prevOrder.second_amount, orderForDeal.second_amount)
    //   //       prevOrder.first_amount = new Decimal(prevOrder.first_amount).minus(volume).toFixed()
    //   //       orderForDeal.first_amount = new Decimal(orderForDeal.first_amount).minus(volume).toFixed()
    //   //       if (new Decimal(orderForDeal.first_amount).eq(0)) {
    //   //         break
    //   //       }
    //   //     }
    //   //     orderForDeal.first_amount = "0"
    //   //     retryStartActions()
    //   //   }
    //   // }
    //   // }
    //   retryStartActions()
    // } else {
    //   earliestOrders.sort(getCbForSortRates(currentMakerType))
    //   if (!earliestOrders[0].exchange_rate) {
    //     const dealIsSell = earliestOrders[0].type === 'sell'
    //     const invertedType = dealIsSell ? 'buy' : 'sell'
    //     const previousOrders = ordersByType[invertedType].filter(i => {
    //       return !new Decimal(i.first_amount).eq(0) && i.timestamp < earliestOrders[0].timestamp && i.exchange_rate
    //     })
    //     previousOrders.sort(getCbForSortRates(earliestOrders[0].type))
    //     for (let prevOrder of previousOrders) {
    //       const volume = Decimal.min(prevOrder.first_amount, earliestOrders[0].first_amount);
    //       const minSecondAmount = Decimal.min(prevOrder.second_amount, earliestOrders[0].second_amount)
    //       prevOrder.first_amount = new Decimal(prevOrder.first_amount).minus(volume).toFixed()
    //       earliestOrders[0].first_amount = new Decimal(earliestOrders[0].first_amount).minus(volume).toFixed()
    //       if (new Decimal(earliestOrders[0].first_amount).eq(0)) {
    //         break
    //       }
    //     }
    //     earliestOrders[0].first_amount = "0"
    //     retryStartActions()
    //     continue
    //   }
    //   currentMakerType = earliestOrders[0].type
    //   currentMarketMakerIdx = earliestOrders[0].idxInTypeArr
    // }
    // console.log({ earliestOrders })
  }
  console.log('Итераций По курсу и времени', [...ordersByType.buy, ...ordersByType.sell].filter(i => !new Decimal(i.first_amount).eq(0)).reduce((acc, i) => {
    if (!acc[i.type][i.exchange_rate]) acc[i.type][i.exchange_rate] = []
    acc[i.type][i.exchange_rate].push(i)
    return acc
  }, { sell: {}, buy: {} }))
  console.log('Матчи по курсу и времени', matches)
  return iterCount
}

const getCbForSortRates = currentOrderType => (a, b) => {
  const currentIsSell = currentOrderType === 'sell'
  const minuend = currentIsSell ? b : a
  const subtrahend = currentIsSell ? a : b
  return new Decimal(minuend.exchange_rate || 0).minus(subtrahend.exchange_rate || 0).toNumber()
}

const getInvertedType = currentType => currentType === 'sell' ? 'buy' : 'sell'

const getOperatorForCurr = currentType => currentType === 'sell' ? 'lte' : 'gte'
